<template>
  <div class="layout">
    <!-- 中间背景图 -->
    <FreelancerBanner />
    <!-- 下面的导航菜单和面包屑和展示区 -->
    <div class="layout-menu">
      <div class="layout-menu-center">
        <div class="layout-menu-left">
          <div class="layout-menu-left-top">
            <button @click="identityChangeCallModal">
              <i class="iconfont icon-change wh-16"></i>
              <span>{{ changeName }}</span>
            </button>
          </div>
          <div class="layout-menu-left-bottom">
            <el-menu
              :default-active="defaultActive"
              :default-openeds="defaultOpens"
              :router="true"
              active-text-color="#00a2e6"
              @select="handleSelect"
              v-loading="loading"
            >
              <el-menu-item index="/home/page" class="menu">
                <template>
                  <Isvg icon-class="main" />
                </template>
                主页
              </el-menu-item>
              <div
                class="freelancer-menu-box list-group-item"
                v-for="(cate, index) in navData"
                :index="String(cate.url)"
                :key="index"
              >
                <!-- 无子菜单的 -->
                <el-menu-item v-if="cate.children.length == 0" :index="String(cate.url)">
                  <template v-slot:title>
                    <Isvg :icon-class="cate.icon" />
                    <span slot="title" style="font-size: 16px">{{ cate.name }}</span>
                  </template>
                </el-menu-item>
                <!-- 有子菜单的 -->
                <el-submenu v-if="cate.children.length > 0" :index="String(cate.url)">
                  <template v-slot:title>
                    <Isvg :icon-class="cate.icon" />
                    <span slot="title" style="font-size: 16px">{{ cate.name }}</span>
                  </template>
                  <el-menu-item-group>
                    <el-menu-item
                      v-for="(cat, key) in cate.children"
                      :key="key"
                      :index="String(cat.url)"
                      >{{ cat.name }}
                    </el-menu-item>
                  </el-menu-item-group>
                </el-submenu>
              </div>
            </el-menu>
          </div>
        </div>

        <div class="layout-menu-right">
          <div class="layout-menu-right-content">
            <div class="list-group-item">
              <router-view />
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog :visible.sync="identityDalogVisible" width="30%">
      <h3 class="identity-dialog-title" slot="title">{{
        identityDialogText.identityDialogTitle
      }}</h3>
      <div class="identity-dialog-wrap">
        <div class="img">
          <img v-if="identity == 1" src="@/assets/icons/tem/release.png" alt="" />
          <img v-else src="@/assets/icons/tem/receive.png" alt="" />
        </div>
        <span style="font-size: 20px; font-weight: bold">{{
          identityDialogText.identityDialogDes_1
        }}</span>
        <span>{{ identityDialogText.identityDialogDes_2 }}</span>
      </div>
      <div class="identity-dialog-footer" slot="footer">
        <div @click="identityDalogVisible = false">取消</div>
        <div @click="changefreelancerIdentity">确定</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import { getNav } from '@/api/user';
  import axios from 'axios';
  import { FreelancerBanner } from '@/_components/Pages/freelancer';

  export default {
    components: {
      FreelancerBanner
    },
    data() {
      return {
        imageUrl: '',
        loading: false,
        changeName: '',
        navData: [],
        defaultProps: { value: 'name', label: 'name', children: 'children' },
        activeMenu: '',
        indexBreadcrumbs: [],
        breadcrumbListData: [],
        defaultOpens: [],
        currentUser: {},
        identity: 1,
        identityDalogVisible: false,
        identityDialogText: {
          identityDialogTitle: '切换为雇主身份',
          identityDialogDes_1: '',
          identityDialogDes_2: ''
        }
      };
    },
    created() {
      // 重新定义set方法
      const signSetItem = localStorage.setItem;
      localStorage.setItem = function (key, val) {
        let setEvent = new Event('setItemEvent');
        setEvent.key = key;
        setEvent.newValue = val;
        window.dispatchEvent(setEvent);
        signSetItem.apply(this, arguments);
      };

      this.$nextTick(function () {
        this.loadCurrentNavList();
      });
      this.breadcrumbList();

      this.currentUser = this.$store.getters['useUserStore/getUserInfo'];
    },
    methods: {
      handleAvatarSuccess(res, file) {
        this.imageUrl = URL.createObjectURL(file.raw);
      },
      beforeAvatarUpload(file) {
        const isJPG = file.type === 'image/jpeg';
        const isLt2M = file.size / 1024 / 1024 < 2;

        if (!isJPG) {
          this.$message.error('上传头像图片只能是 JPG 格式!');
        }
        if (!isLt2M) {
          this.$message.error('上传头像图片大小不能超过 2MB!');
        }
        return isJPG && isLt2M;
      },

      loadCurrentNavList(identity = 1) {
        // 加载路由
        const userId = this.currentUser.id;
        let url = '';

        identity = localStorage.getItem('identity_' + userId);
        this.$store.state.identity = identity;
        if (identity == 1) {
          localStorage.setItem('identity_' + userId, 1);
          this.changeName = '切换为雇主';
          url = '/js/homeFreelancerRouter.json';
        } else {
          localStorage.setItem('identity_' + userId, 2);
          this.changeName = '切换为自由职业者';
          url = '/js/homeHirerRouter.json';
        }

        axios.get(url).then((res) => {
          this.navData = res.data;
          this.navData.forEach((ele) => {
            if (ele.children.length > 0) {
              this.defaultOpens.push(ele.url);
            }
          });
          this.loading = false;
        });
      },

      async loadNavList(identity = 1) {
        this.loading = true;
        let userId = this.currentUser.id;

        identity = localStorage.getItem('identity_' + userId);
        if (identity == 1) {
          localStorage.setItem('identity_' + userId, 1);
          this.changeName = '切换为雇主';
        } else {
          localStorage.setItem('identity_' + userId, 2);
          this.changeName = '切换为自由职业者';
        }

        const res = await getNav({ type: identity });
        if (res.statusCode == 200) {
          this.navData = res.data;
          this.navData.forEach((ele) => {
            if (ele.children.length > 0) {
              this.defaultOpens.push(ele.url);
            }
          });
          this.loading = false;
        } else {
          this.loading = false;
        }
      },
      identityChangeCallModal() {
        let identity = localStorage.getItem('identity_' + this.currentUser.id);
        this.identity = identity;
        if (identity == 1) {
          this.identityDialogText.identityDialogTitle = '切换为雇主身份';
          this.identityDialogText.identityDialogDes_1 = '当前为灵工身份，是否切换为雇主';
          this.identityDialogText.identityDialogDes_2 = '切换后可发布任务、管理雇员';
        } else {
          this.identityDialogText.identityDialogTitle = '切换为灵工身份';
          this.identityDialogText.identityDialogDes_1 = '当前为雇主身份，是否切换为灵工';
          this.identityDialogText.identityDialogDes_2 = '切换后可接任务、管理任务';
        }

        this.identityDalogVisible = true;
      },
      //要切换的身份
      changefreelancerIdentity() {
        this.identityDalogVisible = false;
        this.loading = true;
        let identity = localStorage.getItem('identity_' + this.currentUser.id);

        if (identity == 1) {
          this.$notify({
            type: 'success',
            title: '通知',
            message: '您已切换到 [ 雇主 ] 身份'
          });
          localStorage.setItem('identity_' + this.currentUser.id, 2);
        } else {
          this.$notify({
            type: 'success',
            title: '通知',
            message: '您已切换到 [ 自由职业者 ] 身份'
          });
          localStorage.setItem('identity_' + this.currentUser.id, 1);
        }

        this.loadCurrentNavList();
      },
      handleNodeClick(navData) {
        console.log(navData);
      },
      buildIconPath(iconName) {
        if (iconName == undefined || iconName == null || iconName == '') {
          return;
        }
        return require('@/icons/svg/nav/' + iconName + '.svg');
      },
      // 获取选中的菜单的数据
      handleSelect(index, indexPath) {
        this.indexBreadcrumbs = indexPath; // indexPath为一个数组，里面包含选中的菜单，和菜单的index标识
      },
      // 获取面包屑数据(将选中菜单的数据进行处理,得到面包屑的数据)
      breadcrumbList() {
        let breadcrumbs = [];
        let menuList = this.navData;
        this.indexBreadcrumbs.map((item) => {
          for (let i = 0; i < menuList.length; i++) {
            if (item === menuList[i].path) {
              breadcrumbs.push(menuList[i]);
              if (menuList[i].child) {
                menuList = menuList[i].child;
              }
              break;
            }
          }
        });
        this.breadcrumbListData = breadcrumbs; // 返回的一个数组，里面只包含选中的菜单
      }
    },
    watch: {
      // 监听路由的改变(实时改变面包屑数据)
      $route() {
        this.breadcrumbList();
      }
    },
    computed: {
      // 获取路由,（监听菜单的改变）
      defaultActive() {
        if (this.$route.path == '/home') {
          return '/home/page';
        }
        return this.$route.path;
      }
    }
  };
</script>

<style lang="less" scoped>
  .layout {
    background-color: #f8f8f8;

    .layout-menu {
      width: 1200px;
      margin: 20px auto;

      .layout-menu-center {
        display: flex;
        justify-content: space-between;

        .layout-menu-left {
          width: 240px;

          ul {
            padding-bottom: 40px;
          }

          // 菜单导航上面的切换部分
          .layout-menu-left-top {
            text-align: center;
            margin-bottom: 20px;
            box-shadow: 0 10px 12px 0 rgba(0, 0, 0, 0.3);

            button {
              width: 240px;
              height: 50px;
              background-color: #00a2e6;
              color: #fafafa;
              font-size: 16px;
              cursor: pointer;
              display: flex;
              align-items: center;
            }

            i {
              margin-left: 20px;
              margin-right: 15px;
            }
          }

          .layout-menu-left-bottom {
            box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);

            svg {
              width: 14px;
              height: 14px;
              margin-right: 10px;
              color: #b5b5b5;
            }

            svg:hover {
              color: #00ace6;
            }
          }
        }

        .layout-menu-right {
          margin-bottom: 40px;
          //box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
          width: 940px;
          min-height: 930px;

          .layout-menu-right-top {
            width: 940px;
            height: 50px;
            background: #fff;

            /deep/ .el-breadcrumb {
              line-height: 50px;
              margin-left: 40px;

              span {
                font-size: 16px;
                color: #474747;
              }
            }
          }

          .layout-menu-right-content {
            min-height: 930px;
          }
        }
      }
    }
  }

  /deep/ .el-menu-item,
  /deep/ .el-submenu__title {
    height: 45px;
    line-height: 45px;
  }

  /deep/ .el-menu-item-group__title {
    padding: 0;
  }

  /deep/ .el-menu-item:focus,
  /deep/ .el-menu-item:hover {
    background-color: #fff;
    color: #00a2e6;
  }

  /deep/ .el-submenu__title:hover {
    background-color: #fff;
  }

  /deep/ .el-submenu__title {
    font-size: 16px;
  }

  .menu {
    font-size: 16px;
    margin-top: 10px;
  }

  /deep/ .el-menu-item {
    color: #575757;
  }

  /deep/ .el-menu {
    border-right: none;
  }
  .identity-dialog-title {
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    line-height: 26px;
  }
  .identity-dialog-wrap {
    text-align: center;
    .img {
      border: none;
      img {
        width: 120px;
        height: 120px;
      }
    }
    span {
      font-size: 16px;
      margin-top: 20px;
      display: block;
    }
  }
  .identity-dialog-footer {
    margin-top: 118px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    div {
      width: 180px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      font-size: 18px;
      border-radius: 20px;
      cursor: pointer;
      &:first-of-type {
        border: 1px solid #dcdddd;
      }
      &:last-of-type {
        color: #fff;
        background: linear-gradient(270deg, #00b4ff 0%, #1dd0d5 100%);
      }
    }
  }
</style>
