<template>
  <div class="star-list">
    <isvg
      v-for="item in 5"
      :key="item"
      :icon-class="item > grade ? 'five-pointed-star-disabled' : 'five-pointed-star'"
    />
    <span>{{ total }}</span>
  </div>
</template>

<script>
  export default {
    props: {
      total: {
        default: 0
      }
    },
    computed: {
      grade() {
        const total = this.total + '';
        if (~total.indexOf('.')) {
          return total;
        } else {
          const len = total.length;
          const tag = total.indexOf('.');
          return total.slice(0, -(len - tag));
        }
      }
    }
  };
</script>

<style lang="less" scoped>
  .star-list {
    i {
      width: 12px;
      height: 12px;
      display: inline-block;
      background-position: 100% 100%;
      background-size: contain;
      background-repeat: no-repeat;
    }

    span {
      margin-left: 6px;
      font-size: 12px;
      vertical-align: middle;
      color: #575757;
    }
  }
</style>
